/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.icon1-color {
  color: #19ccad !important;
  font: normal normal bold 24px/30px Montserrat !important;
  opacity: 1 !important;
}
.icon2-color {
  color: #02cfe9 !important;
  opacity: 1;
  font: normal normal bold 24px/30px Montserrat !important;
}
.icon3-color {
  color: #7367f0 !important;
  background: #eeedfd 0% 0% no-repeat padding-box !important;
  opacity: 1;
  font: normal normal bold 24px/30px Montserrat !important;
}
.icon4-color {
  color: #ea5455 !important;
  opacity: 1;
  font: normal normal bold 24px/30px Montserrat !important;
}
.icon5-color {
  color: #eaab54 !important;
  opacity: 1;
  font: normal normal bold 24px/30px Montserrat !important;
}
.icon6-color {
  color: #5aea54 !important;
  opacity: 1 !important;
  font: normal normal bold 24px/30px Montserrat !important;
}
.icon7-color {
  color: #54eab6 !important;
  opacity: 1 !important;
  font: normal normal bold 24px/30px Montserrat !important;
}
.icon8-color {
  color: #ea54cd !important;
  opacity: 1 !important;
  font: normal normal bold 24px/30px Montserrat !important;
}

.text-color {
  text-align: left;
  font: normal normal bold 16px/25px Poppins;
  letter-spacing: 0px;
  color: #191c1d;
  opacity: 1;
}

.card-color {
  background-color: #e9eff2 !important;
  color: #43494b !important;
  opacity: 1;
  height: 25px;
}

.card-footer {
  background-color: #e9eff2 !important;
  color: #43494b !important;
  opacity: 1;
  height: 65px;
  width: 1200px;
}

.card-Modal {
  background: #f7fbfc 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  width: 300px;
  height: 288px;
}

[dir='ltr'] .vertical-layout .main-menu .navigation a.active {
  background: transparent linear-gradient(90deg, #4d55c5 0%, #36c7d3 100%) 0% 0%
    no-repeat padding-box !important;
  box-shadow: 0px 5px 10px #2cbfc865 !important;
  border-radius: 7px !important;
}

.btn.btn-primary {
  //height: 38px !important;
  background: transparent linear-gradient(270deg, #14aeb4 39%, #6333cc 100%) 0%
    0% no-repeat padding-box !important;
  //border-radius: 16px !important;
  border: none !important;
}

.btn.btn-primary:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
}

.btn.btn-dark {
  background: linear-gradient(270deg, #f1f3f3 100%, #f1f3f3 100%) 0% 0%
    no-repeat padding-box !important;
  border: 2px solid #191c1d !important;
  height: 44px !important;
  border-radius: 26px !important;
  color: #191c1d !important;
}

.btn.btn-dark:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
  color: #f1f3f3 !important;
}

html[dir] body {
  background-color: #f7fbfc;
}

.text {
  font: normal normal bold 72px/108px Poppins !important;
  letter-spacing: 0px;
  color: #191c1d;
  opacity: 1;
  text-align: left;
}
.text-recovery {
  font: normal normal bold 52px Poppins !important;
  letter-spacing: 0px;
  color: #191c1d;
  opacity: 1;
  text-align: left;
}

.text-recovery-sub {
  text-align: left;
  font: normal normal normal 16px Poppins;
  color: #636a6c;
}
.text-recovery-subtitle {
  text-align: left;
  font: normal normal 600 14px/21px Poppins;
  color: #636a6c;
}

.text-recovery-to-login {
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  color: #3ebef2;
}
.text-data-encryp {
  text-align: left;
  font: normal normal normal 12px/18px Poppins;
  color: #636a6c;
}
.text-card {
  font: normal normal bold 16px/16px Poppins !important;
  letter-spacing: 0px;
  color: #191c1d;
  opacity: 1;
  text-align: left;
}

.text-login {
  text-align: left;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  top: 0px;
  left: 0px;
  width: 670px;
  height: 28px;
}

.text-subtitle {
  top: 30px;
  left: 201px;
  width: 206px;
  height: 28px;
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #3ebef2;
}

.text-Label {
  text-align: left;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.card-login {
  left: 0px;
  width: 714px;
  height: 240px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #d5e2e5b2;
  border-radius: 30px;
  opacity: 1;
}

.card-recovery {
  width: 500px;
  height: 271px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 50px #d5e2e5b2;
  border-radius: 30px;
}

.card-login2 {
  left: 0px;
  @media (min-width: 576px) {
    width: 714px;
    height: 95px;
  }
  background: #f7fbfc 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 30px 30px;
  opacity: 1;
}


.image-recovery {
  width: 95%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.backgroudRecovery {
  background-image: url('../../assets/images/illustration/backgroundRecovery.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.copyright {
  height: 75px;
  display: flex;
  align-items: center;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #43494b;
  background: #EAEEEF 0% 0% no-repeat padding-box;
  opacity: 0.7;
}

.btn.btn-gradient {
  height: 800px !important;
  height: 44px !important;
  background: transparent
    linear-gradient(270deg, #40dd6e 0%, #14aeb4 39%, #6333cc 100%) 0% 0%
    no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
  color: #f1f3f3 !important;
  text-align: center !important;
}

.btn.btn-gradient:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
  color: #f1f3f3 !important;
}

.img-portada {
  background: transparent 0% 0% no-repeat padding-box !important;
  mix-blend-mode: multiply !important;
  opacity: 1;
}

.btn.btn-black {
  background: #000000 !important;
}

.nav-pill {
  font: normal normal bold 16px/16px !important;
  color: #636a6c !important;
}

.nav-pill :hover {
  color: #000000 !important;
}

.btn.btn-secondary {
  height: 44px !important;
  background: #eaeeef 0% 0% no-repeat padding-box !important;
  border: none !important;
  //color: #818586 !important;
}

.active .btn.btn-secondary,
.btn.btn-secondary:hover {
  background: transparent linear-gradient(270deg, #36c7d3 39%, #4d55c5 100%) 0%
    0% no-repeat padding-box !important;
  color: #f1f3f3 !important;
}

.active .nav-link.active {
  color: #000;
}

.btn.btn-gradient1 {
  background: transparent linear-gradient(270deg, #36c7d3 39%, #4d55c5 100%) 0%
    0% no-repeat padding-box !important;
  border: none !important;
  //color: #818586 !important;
}

.btn.btn-gradient10 {
  background: transparent linear-gradient(270deg, #40dd6e 10%, #7078e7 100%) 0%
    0% no-repeat padding-box !important;
  border: none !important;
  color: #fff;
  //color: #818586 !important;
}
.btn.btn-gradient11 {
  background: transparent linear-gradient(90deg, #eb121d 10%, #e9d41b 100%) 0%
    0% no-repeat padding-box !important;
  border: none !important;
  color: #fff;

  //color: #818586 !important;
}

.btn.btn-gradient11:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
  color: #f1f3f3 !important;
}

.btn.btn-gradient2 {
  background: transparent linear-gradient(270deg, #40dd6e 39%, #33afcc 100%) 0%
    0% no-repeat padding-box !important;
  border: none !important;
  //color: #818586 !important;
  width: 30px !important;
  height: 30px !important;
}

.btn.btn-gray {
  background: #818586;
  color: #f1f3f3 !important;
}

.btn.btn-black1 {
  background: #000000 !important;
  color: #f1f3f3 !important;
}

.text-filter {
  text-align: left !important;
  font: normal normal 600 14px/21px !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  opacity: 1 !important;
}

.text-blue {
  color: #6070ff !important;
}

.btn.btn-black2 {
  background: #000000 !important;
  color: #f1f3f3 !important;
}

.btn.btn-black2:hover {
  background: #ff6969 !important;
  color: #f1f3f3 !important;
}

.text-center {
  text-align: center !important;
  font: normal normal 600 22px/33px !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;
}

.text-color-grey {
  color: #b9b9c3;
  height: 19px !important;
}

.number-size {
  text-align: left !important;
  font: normal normal bold 36px/44px Montserrat !important;
  letter-spacing: 0px !important;
  color: #6d6b7b !important;
  opacity: 1;
}

.btn.btn-primary1 {
  height: 44px !important;
  background: transparent
    linear-gradient(270deg, #40dd6e 0%, #14aeb4 39%, #6333cc 100%) 0% 0%
    no-repeat padding-box !important;
  //border-radius: 16px !important;
  border: none !important;
  color: #f1f3f3 !important;
}

.btn.btn-primary1:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
}

.btn.btn-primary2 {
  height: 34px !important;
  background: transparent linear-gradient(270deg, #40dd6e 0%, #14aeb4 69%) 0% 0%
    no-repeat padding-box !important;
  //border-radius: 16px !important;
  border: none !important;
  color: #f1f3f3 !important;
}

.btn.btn-primary2:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
}

.btn.btn-primary5 {
  height: 34px !important;
  background: transparent linear-gradient(270deg, #dd9638 0%, #d12388 99%) 0% 0%
    no-repeat padding-box !important;
  //border-radius: 16px !important;
  border: none !important;
  color: #f1f3f3 !important;
  width: 115px !important;
}

.btn.btn-primary5:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
}

.btn.btn-primary11 {
  height: 44px !important;
  background: transparent linear-gradient(90deg, #4d55c5 0%, #36c7d3 100%) 0% 0%
    no-repeat padding-box !important;
  border-radius: 36px !important;
  //border: none !important;
  color: #f1f3f3 !important;
}

.btn.btn-primary1:hover {
  background: #191c1d 0% 0% no-repeat padding-box !important;
}

.chatBody{
  height: 400px;
  overflow-x: hidden;
  overflow-y: hidden;
}

@import './movile.scss';