.card-videoComponent-container {
    max-width: 340px;
}
.footerCartData{
    padding: 16px;
    margin: 0px !important;
    background: #F7FBFC 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 30px 30px;
    position: relative;
    @media (max-width: 576px) {
        padding-bottom: 56px;
        .movile_footer{
            margin-left: 20px;
        }
    }
}
.dataBody_Card{
    padding: 16px;
    margin: 0px !important;
}
.img_evente{
    position: absolute;
    width: 200px;
    height: 200px;
    right: 0px;
    bottom: -5px;
}
@media (min-width: 576px) {
    .hidden_destop {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 576px) {
    .card-videoComponent-container{
        width: 100%;
        flex: 0 0 auto;
    }
    .img_evente{
        visibility: hidden;
        display: none;
    }
    .container {
        max-width: 100% !important;
    }

    [dir=ltr] .text-recovery,
    [dir=ltr] .text {
        font: normal normal bold 41px/50px Poppins !important;
        letter-spacing: 0px;
        color: #191c1d;
        opacity: 1;
    }

    .card-recovery,
    .card-login {
        width: 100%;
        height: auto;
        opacity: 1;
    }

    .text-login {
        font: normal normal normal 14px/30px Poppins;
        letter-spacing: 0px;
        color: #636a6c;
        top: 0px;
        width: 100%;
        height: auto;
        margin-bottom: 20px !important;
    }

    .img-portada,
    .iden_page {
        display: none !important;
    }

    .responsive_logi_col {
        flex: 0 0 auto;
        width: 100%;
    }

    .card-recovery form {
        grid-gap: 20px;
        padding-bottom: 10px;

        .mt-3 {
            margin-top: 10px !important;
        }
    }

    .modal-dialog.sidebar-sm.modal-lg,
    .modal-dialog.sidebar-xl,
    .modal-dialog.sidebar-lg {
        width: 100% !important;
    }

    .toggle-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .nameUser {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 60px;
    }

    .movile_footer{
        position: absolute;
        bottom: 9px;
        left: 0;
    }
}