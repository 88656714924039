// ================================================================================================
//  File Name: colors.scss
//  Description: Common color file to include color paletter and colors file, its qiore good to
//  have all color classes in a separate file as it's quite heavy.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core
// ------------------------------

// Import variables and mixins
@import 'bootstrap/scss/mixins/gradients';
@import 'core/mixins/hex2rgb';

//box shadow for alert's heading
@import 'core/mixins/alert';

// Color system, always load last
// ------------------------------
@import 'core/colors/palette';
@import 'core/colors/palette-gradient.scss';

.color_card {
  border: 1px solid #b9b9c3 !important;
  background: #f7fbfc;
}

.text-color-black {
  color: #191c1d;
}

.tex {
  text-align: center;
  font-weight: 600;
  font-stretch: normal;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0px;
  color: #6a6b89;
  margin-bottom: 10px;

  &.lef {
    text-align: left;
  }
}

//Custom font

.title-custom-filters {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.subtitle-custom-filters {
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.name-identify {
  // font: normal normal medium 12px/21px Poppins;
  font-size: 12px;
  font-family: 'Poppins';
  letter-spacing: 0px;
  color: #3ebef2;
}

.type-identify {
  font-size: 11px;
  font-family: 'Poppins';
  letter-spacing: 0px;
  color: #636a6c98;
}

.aux-identify {
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.aux-identify-muted {
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #636a6c80;
  opacity: 1;
}

// custom details

.text-name-primary {
  font: normal normal bold 14px/25px Poppins;
  letter-spacing: 0px;
  color: #191c1d;
  opacity: 1;
}

.subtitle-name-primary {
  font: normal normal normal 14px/27px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.subtitle-name-secondary {
  font: normal normal 600 14px/27px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

// Component video

.video-c {
  // border: 1px solid red;
}

.video-container {
  width: 85%;
  aspect-ratio: 1.3/0.8;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

.text-resume {
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.text-resume-scroll {
  height: 350px;
  overflow: scroll;
  overflow-x: hidden;
}

.text-resume-scroll::-webkit-scrollbar {
  display: none;
}

//Dashboard

.text-dashboard {
  // font: normal normal medium 10px Montserrat !important;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: medium;
  color: #6d6b7b;
}

.text-dashboard-stats {
  text-align: left;
  // font: normal normal 600 16px/19px Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  letter-spacing: 0px;
  color: #b9b9c3;
  opacity: 1;
}

.text-dashboard-numbers {
  text-align: left;
  // font: normal normal bold 36px/44px Montserrat;
  font-family: Montserrat;
  font-style: normal;
  font-size: 36px;
  font-weight: bold;
  color: #6d6b7b;
  opacity: 1;
}

.text-dashboard-statistics {
  font-size: 20px;
  font-weight: 600;
  font-family: Montserrat;
  color: #6d6b7b;
}

.text-dashboard-subStatistics {
  font-size: 15px;
  font-weight: medium;
  font-family: Montserrat;
  color: #6d6b7b;
}

.container-CardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 576px) {
    min-width: 149px;
    width: 50%;
  }
}

.statistic-Body {
  display: flex;
  margin-bottom: 1.5rem;
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
}